@import '../../../Styles/Main.scss';

.callToActionSection{
    display: flex;
    align-items: center;

    width: 100%;
    min-height: 350px;
    background: #f1f1f1;
    margin: 48px 0;

    background-image: url('../../../Assets/imgs/zig-zag-pattern.png');
    background-size: 400px;
    background-attachment: fixed;
    background-blend-mode: overlay;

    .container{
        display: flex;
        align-items: center;
        height: 100%;
    }

    .callToActionSection-container{
        display: grid;
        grid-template-columns: 0.4fr 1fr;
        padding: 0 100px;
       
        align-items: center;
        justify-content: center;
        .mascote{
            text-align: center;
            transform: rotateY(180deg);
            @include upDown(4s, 20px);
            img{
                width: 80%;
                filter: drop-shadow(1px 4px 2px rgba(0,0,0,0.2));
            }
        }
        .textToAction{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            small{
                font-size: 19px;
                margin: 25px 0;
                display: block;
                text-align: center;
            }
        }
    }

    @media (max-width: 800px){
        .callToActionSection-container{
            padding: 50px 0;
            display: grid;
            grid-template-columns: 1fr;
            .mascote{
                img{
                    width: 50%;
                }
            }
        }
    }
}