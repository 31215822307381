.plans {
    margin-top: 40px;
    .swiper-container {
        overflow: initial;
    }
    .swiper-wrapper {
        height: 340px;
    }
    .swiper-slide {
        display: flex;
        height: 100%;
        min-width: 250px;
        justify-content: center;
        transition: all 0.1s ease;
    }
    &.mob {
        .swiper-slide {
            &.swiper-slide-next {
                transform: translateX(-19vw);
            }
            &.swiper-slide-prev {
                transform: translateX(19vw);
            }
        }
    }
}
