/* Navbar */

@import '../../../Styles/Main.scss'; 

.logo {
    max-width: 190px;
    display: block;
}
.header {
    background-color: #fafafaa8;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    z-index: 50;
    backdrop-filter: blur(2px);
    transition: all 0.5s ease-in-out;
    &.open{
        background-color: #fafafacc;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
    .menuMob {
        max-height: 0;
        display: flex;
        width: 100%;
        overflow: hidden;
        transition: all 0.5s ease-in-out;
        nav{
            display: block;
            width: 100%;
            ul{
                padding: 0 15px;
                li{
                    flex: 1 1 0;
                    button{
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
        &.open {
            max-height: 400px;
        }
    }
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        button {
            margin: 0;
        }
        div {
            display: flex;
            align-items: center;
        }
    }
    nav {
        ul {
            margin: 0;
            display: flex;
            li {
                list-style: none;
                margin: 10px;
                button {
                    span {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: block;
                        max-width: 300px;
                    }
                }
            }
        }
    }
}
