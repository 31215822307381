@import '../../../Styles/Main.scss';

.plans{
    .card{
        padding: 20px;
        max-width: 300px;
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
        .shadowContainer{
            position: absolute;
            width: 100%;
            height: 100%;
            top:0;
            left: 0;
            border-radius: 5px;
            overflow: hidden;
            &:before{
                content: '';
                position: absolute;
                background-color: rgba(0,0,0,0.15);
                width: 100%;
                height: 300px;
                left: 0;
                top: 0;
                transform: translateY(-70%);
                border-radius: 100%;
                box-shadow: 0 0 50px 50px rgba(0,0,0,0.15);
            }
        }
        .clubinho{
            $w: 120px;
            position: absolute;
            display: block;
            top: -69px;
            right: 3px;
            width: $w;
            height: $w;
            @include upDown(2s, 5px);
            img{
                width: 100%;
                filter: drop-shadow(1px 4px 2px rgba(0,0,0,0.2));
            }
        }
        &.emphasis{
            background-color: $vermelho;
            color: $branco !important;
            border-color: $vermelho;
            .card-prices{
                p{
                    font-size: 20px;
                }
            }
        }
        .card-title,
        .card-cta,
        .card-prices{
            margin-top: auto;
            text-align: center;
            display: block;
            width: 100%;
            button{
                display: block;
                width: 100%;
            }
        }
        .card-title{
            font-size: 35px;
            margin-bottom: 15px;
        }
        .card-prices{
            small{
                text-decoration: line-through;
            }
        }
    }
}