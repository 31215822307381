@import '../../../Styles/Main.scss';

.ListaCategorias{
    margin-top: 40px;
    .swiper-container{
        overflow: initial;
    }
    // .swiper-wrapper{
    //     height: 340px;
    // }
    .swiper-slide{
        display: flex;
        height: 100%;
        // min-width: 250px;
        justify-content: center;
    }
}

.ListaCategorias{
    position: relative;
    .btn-swiper{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 3;
        padding: 5px;
        background-color: #FFF;
        transition: all .1s ease-in-out;
        border-radius: 20px;
        svg{
            transition: all .06s ease-in-out;
            color: $vermelho;
        }
        &:hover{
            cursor: pointer;
            background-color: $vermelho;
            color: $branco;
            border-radius: 100px !important;
            @media (min-width: 800px) {padding: 20px;}
            svg{
                color: $branco;
            }
        }
        &.btn-prev-slider{
            left: 0; 
            &:hover{
                @media (min-width: 800px) {
                svg{
                    transform: translateX(-5px);
                }
                left: -20px;} 
                // padding-left: 0;
            }
        }
        &.btn-next-slider{
            right: 0; 
            &:hover{
                @media (min-width: 800px) {
                svg{
                    transform: translateX(5px);
                }
                right: -20px;} 
                // padding-right: 0;
            }
        }
    }
    .swiper-wrapper{
        align-items: center;
    }
}