.modal-clubei{
    .modal-header{
        border-bottom: none !important;
    }
    .modal-body{
        padding: 50px 100px;
    }

    @media (max-width: 800px) {
        .modal-body{
            padding: 30px;
        }
    }

    .loading-button{
        width: 100px;
        height: 30px;
        display: inline-block;
        background-image: url('../../../Assets/imgs/Loading.svg');
        background-position: center;
        background-size: 70px;
        background-repeat: no-repeat;
        
    }
}